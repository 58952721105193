import { combineReducers } from "redux";
import AuthReducer from "./auth";
import MessaggeReducer from "./message";
import PrintsReducer from "./printsReducer";

const rootReducer = combineReducers({
  auth: AuthReducer,
  message: MessaggeReducer,
  prints: PrintsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
