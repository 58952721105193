import { GET_THERMO_PRINTS } from "../action-types/types";

interface IState {
  prints: any[];
}

const initialState = {
  prints: [],
};

const PrintsReducer = (
  state: IState = initialState,
  { type, payload }: any
) => {
  switch (type) {
    case GET_THERMO_PRINTS:
      return { ...state, prints: payload };

    default:
      return state;
  }
};

export default PrintsReducer;
