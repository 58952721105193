import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  useTheme,
  alpha,
} from "@mui/material";
import { deepPurple } from "@mui/material/colors";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation, matchPath } from "react-router-dom";
import Logo from "../../../components/Logo";
import items from "../../../manu-items/menuItems";
import { AppState } from "../../../store/reducers";
import NavItem from "./NavItem";

const NavBar = ({ onMobileClose, openMobile }: any) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const match = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;
  const user = useSelector((state: AppState) => state.auth.user);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        sx={{
          // height: '64px',
          marginBottom: 2,
          px: 2,
          py: 1,
        }}
      >
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          {/* <Logo /> */}
        </Box>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        sx={{
          py: 2,
          px: 1,
          mx: 2,
          my: 1,
          borderRadius: 2,
          bgcolor: alpha(theme.palette.secondary.light, 0.1),
        }}
      >
        <Avatar
          sx={{
            // cursor: "pointer",
            width: "36px",
            height: "36px",
            marginRight: 2,
            fontSize: "18px",
            color: theme.palette.getContrastText(deepPurple[500]),
            backgroundColor: deepPurple[500],
          }}
          // component={RouterLink}
          // src={user.avatar}
          // to="/app/account"
        >
          {user &&
            user.firstName &&
            user.lastName &&
            (user.firstName[0] + user.lastName[0]).toUpperCase()}
        </Avatar>
        <Box>
          <Typography sx={{ color: "primary.dark", fontWeight: 600 }}>
            {user?.firstName + " " + user?.lastName}
          </Typography>
        </Box>
      </Box>
      <Box sx={{}}>
        <List>
          {items.map(
            (item) =>
              (user?.permissions.includes("admin") ||
                user?.permissions.includes(item.permission)) && (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  active={match}
                />
              )
          )}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          sx={{
            "& .MuiDrawer-paper": {
              width: "260px",
              // background: '#111827',
              // color: 'text.secondary',
            },
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          sx={{
            "& .MuiDrawer-paper": {
              width: "260px",
              // background: '#111827',
              // color: 'text.primary',
              // borderRight: 'none',
              // [theme.breakpoints.up('md')]: {
              //   top: '64px'
              // }
            },
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
