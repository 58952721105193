import { formatDistance, format } from "date-fns";
import { pl } from "date-fns/locale";

export {
  formatDistance,
  formatRemainingTime,
  formatToLocalDate,
  formatToFullDate,
};

function formatMileage(x: number) {
  return x.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " km";
}

function formatRemainingTime(date: Date) {
  return formatDistance(new Date(date), new Date(), { locale: pl });
}

function formatToLocalDate(date: Date) {
  return format(new Date(date), "dd.MM.yyyy", { locale: pl });
}

function formatToFullDate(date: Date) {
  return format(new Date(date), "dd.MM.yyyy HH:mm", { locale: pl });
}
