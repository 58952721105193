import { Box } from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent";
import { enviroment } from "../../enviroment";
import PrintForm from "./PrintForm";
import PrintRow from "./PrintRow";
import { Print } from "./types";

const PrintView = () => {
  const [prints, setPrints] = useState<Print[]>([]);

  const fetchPrints = () => {
    axios.get(`${enviroment.api}/thermo-print`).then((res) => {
      setPrints(res.data);
    });
  };

  useEffect(() => {
    fetchPrints();
  }, []);

  const submitted = (print: Print) => {
    downloadPrint(print);
    fetchPrints();
  };

  const downloadPrint = ({ id, trailer, createdAt }: Print) => {
    axios({
      url: `${enviroment.api}/thermo-print/${id}/file`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const fileName = `${trailer.plateNumber}_${format(
        new Date(createdAt),
        "yyyy-MM-dd"
      )}_${new Date().getTime()}.pdf`;
      const href = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <>
      <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
        <PrintForm submitted={submitted} />
        <TableComponent
          init={[
            { header: "Lp.", size: 1 },
            { header: "Data utworzenia", size: 2 },
            { header: "Nr. rejestracyjny", size: 2 },
            { header: "Od", size: 2 },
            { header: "Do", size: 2 },
            { header: "Temp. ustawiona", size: 1 },
            { header: "Temp. otoczenia", size: 1 },
            { header: "", size: 1 },
          ]}
          data={prints}
          component={PrintRow}
          onRowClick={downloadPrint}
        />
      </Box>
    </>
  );
};

export default PrintView;
