export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const GET_THERMO_PRINTS = "GET_THERMO_PRINTS";
export const GET_THERMO_PRINT = "GET_THERMO_PRINT";
export const CREATE_THERMO_PRINT = "CREATE_THERMO_PRINT";
export const DOWNLOAD_THERMO_PRINT = "DOWNLOAD_THERMO_PRINT";
