import axios from "axios";
import { enviroment } from "../enviroment";

const API = enviroment.api;

class AuthService {

  login(credentials: any): Promise<any> {
    return axios.create()
      .post(`${API}/auth/login`, credentials)
      .then((response) => {
        if (response.data && response.data.token) {
          // Extract token from object
          localStorage.setItem('token', response.data.token);
          // TODO save refresh token when implemented in api
          // save user to localstorage
          localStorage.setItem("user", JSON.stringify(response.data.user));
        }

        return response.data.user;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

}

export default new AuthService();