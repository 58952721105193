import { Box, Grid, GridSize } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme: any) => ({
  tableHeader: {},
}));

export interface Props {
  init: InitHeader[];
  data: any;
  component: (item: any) => React.ReactNode;
  onRowClick: any;
}

export interface InitHeader {
  header: string;
  size: GridSize;
}

const TableComponent: React.FC<Props> = ({
  init,
  data,
  component,
  onRowClick,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box mt={4}>
        <Grid
          container
          sx={{
            borderRadius: 1,
            backgroundColor: "primary.main",
            color: "#fff",
            padding: "10px 20px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            cursor: "pointer",
            marginBottom: 2,
            marginRight: 0,
            alignItems: "flex-end",
          }}
        >
          {init.map((i, index) => {
            return (
              <Grid item xs={i.size} key={index}>
                {i.header}
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box>
        {data.map((i: any) => {
          return (
            <Grid
              container
              key={i.id}
              onClick={() => onRowClick(i)}
              sx={{
                borderRadius: 1,
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                padding: "10px 20px",
                cursor: "pointer",
                marginBottom: 1,
                boxShadow: 2,
                "&:hover": {
                  boxShadow: 6,
                },
              }}
            >
              {component({ item: i })}
            </Grid>
          );
        })}
      </Box>
    </>
  );
};

export default TableComponent;
