import React, { ElementType } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { alpha, Button, ListItem, Theme, useTheme } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { Box, SxProps } from '@mui/system';

type Props = {
  className?: string,
  href: string,
  icon: ElementType,
  title: string,
  active: (path: string) => boolean,
}

const NavItem: React.FC<Props> = ({
  className,
  href,
  icon: Icon,
  title,
  active,
  ...rest
}: any) => {
  const theme = useTheme();
  const isActive = active(href);
  const activeStyle: SxProps = {
    borderRight: 'solid 3px',
    borderColor: 'primary.main',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  }
  const activeStyleSub: SxProps = {
    color: 'primary.main',
    fontWeight: 600,
  }

  return (
    <ListItem
      sx={{
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 2,
        ...(isActive && activeStyle)
      }}
      disableGutters
      {...rest}
    >
      <Button
        component={RouterLink}
        to={href}
        sx={{
          color: 'text.primary',
          justifyContent: 'flex-start',
          letterSpacing: 0,
          padding: '10px 8px',
          textTransform: 'none',
          width: '100%',
          ...(isActive && activeStyleSub)
        }}
      >
        {Icon && (
          <Icon
            size="20"
          />
        )}
        <Box component="span" sx={{marginLeft: '10px'}}>
          {title}
        </Box>
      </Button>
    </ListItem>
  );
};

export default NavItem;
