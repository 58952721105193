import React, { Dispatch, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../components/Page";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/auth";
import { AppState } from "../../store/reducers";

const useStyles: any = makeStyles((theme: any) => ({
  root: {
    height: "100%",
  },
  container: {
    backgroundColor: "#fff",
    padding: 20,
    borderRadius: 6,
  },
}));

type Props = {
  auth?: any;
};

const LoginView: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state: AppState) => state);

  useEffect(() => {
    if (state.auth.isLoggedIn) {
      navigate("/app");
    }
  }, [state.auth.isLoggedIn, navigate]);

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" className={classes.container}>
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().max(255).required("Login jest wymagany"),
              password: Yup.string().max(255).required("Hasło jest wymagane"),
            })}
            onSubmit={async ({ username, password }, actions) => {
              //TODO implement login
              actions.setSubmitting(true);
              // @ts-ignore
              dispatch(login({ login: username, password })).then(
                () => {
                  // Resolved,
                  navigate("/app");
                },
                () => {
                  actions.setSubmitting(false);
                }
              );
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Alfa Panel
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Logowanie do panelu
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Login"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  // variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Hasło"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  // variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Zaloguj się
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
