import React from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import TopBar from './TopBar';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  wrapper: {

  },
  contentContainer: {

  },
  content: {

  }
}));

const MainLayout = () => {
  const classes = useStyles();

  return (
    <Box sx={{
      backgroundColor: 'background.default',
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%'
    }}>
      {/* <TopBar /> */}
      <Box sx={{
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 8
      }}>
        <Box sx={{
          display: 'flex',
          flex: '1 1 auto',
          overflow: 'hidden'
        }}>
          <Box sx={{
            flex: '1 1 auto',
            height: '100%',
            overflow: 'auto'
          }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
