import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

type CustomInputProps = { name: string; control: any } & TextFieldProps;

const CustomInput: React.FunctionComponent<CustomInputProps> = ({
  name,
  control,
  label,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField onChange={onChange} value={value} label={label} {...props} />
      )}
    />
  );
};

export default CustomInput;
