import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "../action-types/types";
import AuthService from "../../services/auth.service";
import { Dispatch } from "react";


export const login = (credentials: { login: string, password: string}) => (dispatch: any): Promise<void> => {
  
  return AuthService.login(credentials).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch: Dispatch<{}>) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};