import React from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import theme from "./theme/theme";
import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import routes from "./routes";

const App = () => {
  const routing = useRoutes(routes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routing}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
