import { DateTimePicker } from "@mui/lab";
import React from "react";
import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

type CustomDateTimePickerProps = { name: string; control: any };

const CustomDateTimePicker: React.FunctionComponent<
  CustomDateTimePickerProps
> = ({ name, control, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <DateTimePicker
          onChange={onChange}
          value={value}
          renderInput={(props) => (
            <TextField {...props} label="Data załadunku" />
          )}
        />
      )}
    />
  );
};

export default CustomDateTimePicker;
