import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import TrailerAutocomplete from "../../components/autocomplete/TrailerAutocomplete";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import CustomInput from "../../components/CustomInput";
import { useForm } from "react-hook-form";
import CustomDateTimePicker from "../../components/CustomDateTimePicker";
import axios from "axios";
import { enviroment } from "../../enviroment";
import { NewPrint, Print } from "./types";

const api = enviroment.api;
interface FormType {
  ambientTemp: number;
  setTemp: number;
  plateNumber: string | null;
  startDate: Date;
  endDate: Date;
}

const defaultValues: FormType = {
  ambientTemp: 0,
  setTemp: 0,
  plateNumber: null,
  startDate: new Date(),
  endDate: new Date(),
};

interface Props {
  submitted: (print: Print) => void;
}

const PrintForm: React.FunctionComponent<Props> = ({ submitted }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { control, handleSubmit, reset } = useForm({ defaultValues });
  const onSubmit = async ({
    ambientTemp,
    setTemp,
    startDate,
    endDate,
    plateNumber,
  }: FormType) => {
    setLoading(true);
    const res = await axios.post<Print>(`${api}/thermo-print`, {
      seed: 1,
      plateNumber,
      routes: [{ ambientTemp, setTemp, startDate, endDate }],
    } as NewPrint);

    if (res.status !== 201) {
      setLoading(false);
      return;
    }

    submitted(res.data);
    reset();
    setLoading(false);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form>
        <Box sx={{ margin: 2 }}>
          <Box display="flex" flexDirection="row" sx={{ gap: 2 }}>
            <TrailerAutocomplete name="plateNumber" control={control} />
            <Box sx={{ gap: 2, flexDirection: "column", display: "flex" }}>
              <CustomDateTimePicker name="startDate" control={control} />
              <CustomDateTimePicker name="endDate" control={control} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <CustomInput
                name="ambientTemp"
                control={control}
                type="number"
                label="Temp. otoczenia"
              />
              <CustomInput
                name="setTemp"
                control={control}
                type="number"
                label="Temp. ładunku"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={loading}
              >
                Dodaj
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </LocalizationProvider>
  );
};

export default PrintForm;
