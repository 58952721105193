import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from "../action-types/types";
import { User } from "../../common/types/user.type";

type AuthState = {
  isLoggedIn: boolean,
  user: User | null;
}


const user = localStorage.getItem("user");

const initialState: AuthState = user
  ? { isLoggedIn: true, user: JSON.parse(user) }
  : { isLoggedIn: false, user: null };

const AuthReducer = (state = initialState, action: { type: string, payload: User}) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
}

export default AuthReducer;