import {
  User as UserIcon,
  Truck as TruckIcon,
  Package as PackageIcon,
  DollarSign as DollarIcon,
  LogOut as LogOutIcon,
  Tool as ToolIcon,
  Home as HomeIcon,
  File as DocumentIcon,
  Airplay as AmazonIcon
} from "react-feather";

const items = [
  {
    href: "/app/home",
    icon: HomeIcon,
    title: "Strona główna",
    permission: 'documents'
  },
  {
    href: "/app/documents",
    icon: DocumentIcon,
    title: "Dokumenty",
    permission: 'documents'
  },
  {
    href: "/app/vehicles",
    icon: TruckIcon,
    title: "Pojazdy",
    permission: 'vehicles'
  },
  {
    href: "/app/drivers",
    icon: TruckIcon,
    title: "Kierowcy",
    permission: 'drivers'
  },
  {
    href: "/app/amazon",
    icon: AmazonIcon,
    title: "Amazon",
    permission: 'autobooking'
  },
  {
    href: "/app/map",
    icon: AmazonIcon,
    title: "Map",
    permission: 'map'
  },
  {
    href: "/app/test",
    icon: AmazonIcon,
    title: "Test",
    permission: 'test'
  },
];

export default items;