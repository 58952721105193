import { Grid } from "@mui/material";
import React from "react";
import { formatToFullDate, formatToLocalDate } from "../../common/helpers";
import { Print } from "./types";

interface PrintRowProps {
  item: Print;
}

const PrintRow: React.FunctionComponent<PrintRowProps> = ({ item }) => {
  const route = item?.routes[0];
  return (
    <>
      <Grid item xs={1}>
        {item.id}
      </Grid>
      <Grid item xs={2}>
        {formatToLocalDate(item.createdAt)}
      </Grid>
      <Grid item xs={2}>
        {item.trailer.plateNumber}
      </Grid>
      <Grid item xs={2}>
        {formatToFullDate(route.startDate)}
      </Grid>
      <Grid item xs={2}>
        {formatToFullDate(route.endDate)}
      </Grid>
      <Grid item xs={1}>
        {route.setTemp}
      </Grid>
      <Grid item xs={1}>
        {route.ambientTemp}
      </Grid>
      <Grid item xs={1}>
        Pobierz
      </Grid>
    </>
  );
};

export default PrintRow;
