import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import axios, { AxiosRequestConfig } from "axios";
import { HelmetProvider } from "react-helmet-async";
import authHeader from "./common/authHeader";

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = {
    ...authHeader(),
  };
  return config;
});

// Add a 401 response interceptor
axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (401 === error.response.status) {
      // handle error: inform user, go to login, etc
      // let navigate = useNavigate();
      // navigate("/login");
      console.log("401 invalid credentials");
    } else {
      return Promise.reject(error);
    }
  }
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
