
export enum VehicleObjectType {
  Unknown = 'unknown',
  Part = 'part',
  Document = 'document',
}

export enum ServiceLifeUnit {
  Undefined = '',
  KM = 'km',
  Days = 'days',
  Years = 'years',
}


export type VehicleObject = {
  id?: number;
  type: VehicleObjectType;
  name: string;
  serviceLifeValue?: number;
  serviceLifeUnit?: ServiceLifeUnit;
  cost?: number;
  inputValue?: string;
}

export const serviceLifeUnitSelect: { label: string, value: ServiceLifeUnit }[] = [
 {
   label: 'KM [tyś.]',
   value:  ServiceLifeUnit.KM
 },
 {
   label: 'lata',
   value:  ServiceLifeUnit.Years
 },
 {
   label: 'dni',
   value:  ServiceLifeUnit.Days
 },

]