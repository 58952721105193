import { Box, Container, Typography } from "@mui/material";
import Page from "../../components/Page";
import PrintView from "../print/PrintView";

const HomeView: React.FC = () => {
  return (
    <Page title="Home">
      <Container maxWidth={false}>
        <Box display="flex">
          <PrintView />
        </Box>
      </Container>
    </Page>
  );
};

export default HomeView;
