import { Theme, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { AppState } from "../../store/reducers";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const DashboardLayout = () => {
  const query = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const auth = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    if (!auth.isLoggedIn) {
      navigate("/login");
    }
  }, [auth, navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
          paddingTop: 8,
          paddingLeft: query ? "260px" : 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              flex: "1 1 auto",
              height: "100%",
              overflow: "auto",
              my: 4,
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
