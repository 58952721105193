import { TextField } from "@mui/material";
import { Autocomplete, createFilterOptions } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { enviroment } from "../../enviroment";
import { Trailer } from "../../views/print/types";
import { Controller } from "react-hook-form";

const api = enviroment.api;

type Props = { name: string; control: any };

const filter = (options: string[], params: any) => {
  const filtered = createFilterOptions()(options, params);

  const { inputValue } = params;

  const isExisting = options.some((option) => inputValue === option);
  if (inputValue !== "" && !isExisting) {
    filtered.push(`${inputValue.toUpperCase()}`);
  }

  return filtered;
};

const TrailerAutocomplete: React.FunctionComponent<Props> = ({
  name,
  control,
}) => {
  const [trailers, setTrailers] = useState<string[]>([]);

  const fetchTrailers = async () => {
    const res = await axios.get<Trailer[]>(`${api}/print-trailer`);

    if (res.status !== 200) {
      setTrailers([]);
    }

    setTrailers(res.data.map((t) => t.plateNumber));
  };

  useEffect(() => {
    fetchTrailers();
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          disablePortal
          id="trailer-autocompolete"
          options={trailers}
          sx={{ width: 300 }}
          onChange={(e, newValue) => {
            return onChange(newValue);
          }}
          filterOptions={(options, params) => filter(options, params)}
          value={value}
          clearOnBlur
          freeSolo
          renderInput={(params) => <TextField {...params} label="Naczepa" />}
        />
      )}
    />
  );
};

export default TrailerAutocomplete;

// onChange={(event, newValue) => {

// }}
