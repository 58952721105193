import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, Hidden, IconButton, Toolbar } from "@mui/material";
import React from "react";
import { LogOut as LogOutIcon } from "react-feather";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { logout } from "../../store/actions/auth";

const TopBar = ({ className, onMobileNavOpen, ...rest }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogOut = () => {
    // @ts-ignore
    dispatch(logout());
    navigate("/login");
  };

  return (
    <AppBar sx={{ backgroundColor: 'background.paper', boxShadow: 6, }} elevation={0} {...rest}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton onClick={onMobileNavOpen} size="large">
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">{/* <Logo /> */}</RouterLink>
        <Box flexGrow={1} />
        <Button
          variant="text"
          onClick={handleLogOut}
          startIcon={<LogOutIcon />}
        >
          Wyloguj
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
